<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select
					size="small"
					v-if="isAdmin"
					@change="agentChange"
					v-model="query.agentId"
					filterable
					clearable
					placeholder="代理商"
					class="handle-select mr10"
					style="width: 260px;"
				>
					<el-option v-for="item in agents" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-select size="small" style="width: 260px" v-model="query.companyId" filterable clearable placeholder="企业" class="handle-select mr10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-button size="small" type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
				<el-button size="small" v-if="canAddCompany" type="primary" icon="el-icon-add  el-icon--right" class="handle-add mr100" @click="showForm">添加标签</el-button>
			</div>
			<el-table
				:data="tableData"
				border
				class="table"
				ref="multipleTable"
				header-cell-class-name="table-header"
				:row-style="{ height: '20px' }"
				:cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }"
				:row-class-name="tableRowClassName"
			>
				<el-table-column prop="label" width="250" label="标签类型" align="center"></el-table-column>
				<el-table-column prop="value" label="标签名称" align="center">
					<template #default="scope">
						<el-tag style="margin-right: 10px;" v-for="(item, index) in scope.row.value">{{ item.str }}</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="180" align="center">
					<template #default="scope">
						<!-- <el-button size="small" :disabled="canEditCompany" type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row.id)">编辑</el-button> -->
						<el-button size="small" v-if="canAddCompany" type="text" icon="el-icon-delete" class="red" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
						<el-button size="small" v-else type="text" icon="el-icon-delete" class="red" disabled>删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination
					background
					layout="total, prev, pager, next"
					:current-page="query.pageIndex"
					:page-size="query.pageSize"
					:total="pageTotal"
					@current-change="handlePageChange"
				></el-pagination>
			</div>
		</div>

		<el-dialog title="添加标签" v-model="addVisible" width="450px" @close="closeDialog('form')" :close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="标签类型" prop="label"><el-input size="small" v-model="form.label"></el-input></el-form-item>
				<!-- <el-form-item label="标签名称" prop="value"><el-input size="small" v-model="form.value"></el-input></el-form-item> -->
				<el-form-item label="标签选项" prop="value">
					<el-tag style="margin-right: 10px;" :key="tag" v-for="tag in form.value" closable :disable-transitions="false" @close="handleClose(tag)">{{ tag }}</el-tag>
					<el-input
						class="input-new-tag"
						v-if="inputVisible"
						v-model="inputValue"
						ref="saveTagInput"
						size="small"
						@keyup.enter.native="handleInputConfirm"
						@blur="handleInputConfirm"
					></el-input>
					<el-button v-else class="button-new-tag" size="small" @click="showInput">+标签名</el-button>
				</el-form-item>
			</el-form>
			<span class="dialog-footer" style="display: flex;flex-direction: row;justify-content: end;">
				<el-button size="small" @click="closeDialog">取消</el-button>
				<el-button size="small" type="primary" @click="addRecordLabel('form')">确定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { list, delCompany, fetchCompanyById, fetchCompanyByAgentId, uptoken, uptoken1 } from '../api/companyIndex.js';
import { fetchAgent } from '../api/agentIndex';
import { fetchDefaultCompanies } from '../api/companyIndex';
import { recordLabel, addRecordLabel, deleteRecordLabel } from '../api/tag.js';

export default {
	name: 'company',
	data() {
		return {
			query: {
				companyId: '',
				userId: localStorage.getItem('user')
			},

			tableData: [],
			companys: [],
			agents: [],
			addVisible: false,
			canAddCompany: true,

			isAdmin: false,
			pageTotal: 0,
			loading1: false,
			form: {
				label: '',
				value: [],
				companyId: ''
			},
			loading: false,
			inputValue: '',
			inputVisible: false
		};
	},

	created() {
		let role = localStorage.getItem('ms_role');
		console.log(role, '角色');
		if (role === 'normal') {
			this.canAddCompany = false;
			this.getData();
		}

		if (role === 'admin') {
			this.isAdmin = true;
		}
		this.init();
		// this.getuptoken()
	},
	methods: {
		handleClose(tag) {
			console.log(tag, '方法');
			this.form.value.splice(this.form.value.indexOf(tag), 1);
		},

		showInput() {
			this.inputVisible = true;
			this.$nextTick(_ => {
				this.$refs.saveTagInput.$refs.input.focus();
			});
		},

		handleInputConfirm() {
			let inputValue = this.inputValue;
			if (inputValue) {
				this.form.value.push(inputValue);
			}
			this.inputVisible = false;
			this.inputValue = '';
		},
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		init() {
			fetchAgent(localStorage.getItem('user')).then(res => {
				this.agents = res.data;
			});
			let data1 = {
				currentUserId: localStorage.getItem('user')
			};
			fetchDefaultCompanies(data1).then(res => {
				this.companys = res.data;
				if (res.data.length > 0) {
					this.query.companyId = this.companys[0].value;
					this.query.pageIndex = 1;
					this.getData();
				} else {
					console.log('22222');
				}
			});
		},
		agentChange(agentId) {
			this.query.companyId = '';
			this.query.userId = '';
			this.form.companyId = '';
			this.form.userId = '';
			if (agentId) {
				let data = {
					agentId: agentId
				};
				fetchCompanyByAgentId(data).then(res => {
					this.companys = res.data;
				});
			}
		},

		getData() {
			this.query.userId = localStorage.getItem('user');
			recordLabel(this.query).then(res => {
				if (res.code === 200) {
					this.tableData = res.data;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		showForm() {
			if (this.query.companyId) {
				this.form.value = [];
				this.addVisible = true;
			} else {
				this.$message.error('请选择企业');
			}
		},

		// 点击确定上传信息
		addRecordLabel(formName) {
			// this.loading1 = true;
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.loading1 = true;
					this.form.userId = localStorage.getItem('user');
					this.form.companyId = this.query.companyId;
					this.form.value = this.form.value.join();
					addRecordLabel(this.form).then(res => {
						if (res.code === 200) {
							this.$message.success('添加成功');

							this.loading1 = false;
							this.addVisible = false;
							this.getData();
						} else {
							this.$message.success(res.message);

							this.loading1 = false;
							this.addVisible = false;
							this.getData();
						}
					});
				}
			});
		},
		// 编辑操作
		handleEdit(index, id) {
			this.addVisible = true;
			this.idx = index;
		},
		// 点击修改信息
		updateCompany(formName) {
			// this.loading2 = true;
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.loading2 = true;
					updateCompany(this.form).then(res => {
						if (res.code === 200) {
							this.$message.success('修改成功');
							this.getData();
							this.loading2 = false;
						}
						this.addVisible = false;
						this.loading2 = false;
					});
				}
			});
		},
		// 触发搜索按钮
		handleSearch() {
			this.query.pageIndex = 1;
			this.getData();
		},
		// 删除操作
		handleDelete(index, row) {
			// 二次确认删除
			this.$confirm('确定要删除吗？', '提示', {
				type: 'warning'
			})
				.then(() => {
					let data = {
						index: index,
						userId: localStorage.getItem('user'),
						companyId: this.query.companyId
					};
					deleteRecordLabel(data).then(res => {
						if (res.code == 200) {
							this.$message.success('删除成功');
							this.pageTotal = this.pageTotal - 1;
							this.tableData.splice(index, 1);
						} else {
							this.$message.error(res.message);
						}
					});
				})
				.catch(() => {});
		},
		// 分页导航
		handlePageChange(val) {
			this.query.pageIndex = val;
			this.getData();
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		// 点击取消
		closeDialog(formName) {
			this.addVisible = false;
			(this.form = {
				template4: '',
				template5: ''
			}),
				this.resetForm(formName);
		}
	}
};
</script>

<style>
.handle-box {
	margin-bottom: 20px;
}

/* a:hover {
	background: #66b1ff;
} */
.handle-select {
	width: 240px;
}

.table {
	width: 100%;
	font-size: 14px;
}
.red {
	color: #f56c6c;
}
.mr10 {
	margin-right: 10px;
}

.mr100 {
	position: absolute;
	right: 100px;
}

.myRight {
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.handle-input {
	width: 200px;
	display: inline-block;
}
.el-form-item__label {
	font-size: 13px !important;
}

.hide .el-upload--picture-card {
	display: none;
}
.content_top {
	display: flex;
	justify-content: space-around;
}
.content_bottom {
	display: flex;
	justify-content: space-around;
}
.content_middle {
	display: flex;
	margin: 0.625rem 0 0.625rem 0;
	/* margin-bottom: 0.625rem; */
	justify-content: space-around;
}
.ywlx:hover {
	color: #00a854;
	background: none;
}
.content_top {
	display: flex;
	margin: auto;
}
</style>
